<template>
    <uw-content title="仪表盘">

        <!-- 筛选条件 -->
        <div :style="{ marginBottom: '10px' }">
            <hrm-employee-select-range @onChange="UpdateEmployeeRang" :style="{ marginRight: '5px' }" />
            <uw-date-range @onChange="UpdateDataRang" />
        </div>

        <card-sale-base :props="{ date_type: date_type, date_start: date_start, date_end: date_end, users_ids: users_ids  }" />
    </uw-content>
</template>

<script>
export default {
    data() {
        return {
            date_type: 'month',
            date_start: this.$lodash.date_range('month')[0],
            date_end: this.$lodash.date_range('month')[1],
            users_ids: [this.$store.state.user.id],
        }
    },

    methods: {
        UpdateDataRang({ date_type, date_start, date_end }) {
            this.date_type = date_type
            this.date_start = date_start
            this.date_end = date_end
        },

        UpdateEmployeeRang(arr) {
            this.users_ids = arr
        }
    }
}
</script>